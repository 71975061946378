import React, { Component } from 'react';

class Footer extends Component {
    render(){
        return (
            <footer>
                <div className="copyright">
                    <h4>Powered by <a href="http://lafabrica.agency" target="_blank" rel="noopener noreferrer">LaFábrica</a> | © 2019 Alliance Group.</h4>
                </div>
            </footer>
        )
    }
}

export default Footer;
import React, { Component } from 'react';

import $ from 'jquery';

import '../../assets/css/page404.css'

class Page404 extends Component{
    componentDidMount(){
        $('#app').addClass('not');
    }
    render(){
        return(
            <div className="not-found">
                <div>
                    <h1>404</h1>
                    <p>NOT FOUND</p>    
                </div>
            </div>
        )
    }
}

export default Page404;
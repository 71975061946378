import React from 'react';

import { Route, Switch } from 'react-router-dom'; 

import App from './components/App';
import Home from './components/pages/Home';
import Page404 from './components/pages/Page404';

const AppRoutes = () =>
<App>
    <Switch>
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
        <Route component={Page404} />
    </Switch>
</App>

export default AppRoutes;
import React, { Component } from 'react';


class Menu extends Component{
    render(){
        return(
            <div className="menu-box">
                <div className="center">
                    <a href={`${process.env.PUBLIC_URL}/#nosotros`}>Nosotros</a>
                    <a href={`${process.env.PUBLIC_URL}/#ubicacion`} >Ubicación</a>
                    <a href={`${process.env.PUBLIC_URL}/#servicios`} >Servicios</a>
                    <a href={`${process.env.PUBLIC_URL}/#equipo`}>Equipo</a>
                    <a href={`${process.env.PUBLIC_URL}/#contactenos`}>Contáctenos</a>
                    <a href="https://www.alliancegroupca.com/documentos/">Recursos</a>
                </div>
            </div>
        );
    }
}

export default Menu;
import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Menu from './template/Menu.js';
import Header from './template/Header.js';
import Content from './template/Content.js';
import Footer from './template/Footer.js';

import $ from 'jquery';

import {Helmet} from 'react-helmet';

import img1 from '../assets/images/logo.png';
import img2 from '../assets/images/home.png';
import img3 from '../assets/images/about.jpg';
import img4 from '../assets/images/location.png';
import img5 from '../assets/images/services.png';
import img6 from '../assets/images/team.png';
import img7 from '../assets/images/contact.png';
import img8 from '../assets/images/services/aml.jpg';
import img9 from '../assets/images/services/auditoria.jpg';
import img10 from '../assets/images/services/financiera.jpg';
import img11 from '../assets/images/services/outsources.jpg';
import img12 from '../assets/images/services/s-1.png';
import img13 from '../assets/images/services/s-2.png';
import img14 from '../assets/images/services/s-3.png';
import img15 from '../assets/images/services/s-4.png';
import img16 from '../assets/images/services/s-5.png';
import img17 from '../assets/images/services/tecnologia.jpg';
import img18 from '../assets/images/team/costarica.jpg';
import img19 from '../assets/images/team/elsalvador.jpg';
import img20 from '../assets/images/team/guatemala.jpg';
import img21 from '../assets/images/team/honduras.jpg';
import img22 from '../assets/images/team/panama.jpg';


class App extends Component {
  static propTypes = {
    children : PropTypes.object.isRequired
  }
  componentDidMount(){
    var imageList = [img1,img2,img3,img4,img5,img6,img7,img8,img9,img10,img11,img12,img13,img14,img15,img16,img17,img18,img19,img20,img21,img22];
    imageList.forEach((image) => {
        new Image().src = image
    });

    $('header').find('.menu').on('click','.hamburger', function () {
      $('header').toggleClass('on');
      $(this).toggleClass('is-active');
      $('.menu-box').toggleClass('on');
    });

    $('.menu-box').find('.center').on('click','a', function () {
      $('header').find('.menu').find('.hamburger').click();
    });
  }
  render(){
    const { children } = this.props;
    return (
      <div className="content">
        <Helmet>
          <title>Alliance Group | Somos su mejor opción en Outsourcing de Contabilidad, Back Office y Auditoría</title>
          <meta name="description" content="Nuestros consultores le ayudarán a aumentar la rentabilidad de sus negocios, proporcionándole servicios de apoyo para desarrollar políticas empresariales, estrategias, mejorar sus procesos y sus sistemas de control operativo y financiero." />
        </Helmet>
        <Menu />
        <Header />
        <Content page={children} />
        <Footer />
      </div>
    )
  }
  
}

export default App;

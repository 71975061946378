import React, { Component } from 'react';
import logo from '../../assets/images/logo.png';

class Header extends Component{
    render(){
        return(
            <header>
                <div className="menu">
                    <div className="hamburger hamburger--collapse"><div className="hamburger-box"><div className="hamburger-inner"></div></div></div>
                </div>
                
                <a href={`${process.env.PUBLIC_URL}/`} className="logo">
                    <img src={logo} alt="Alliance Group" /> 
                </a>

                <ul className="menu-extra" id="menu">
                    <li data-menuanchor="nosotros"><a href="#nosotros">Nosotros</a></li>
                    <li data-menuanchor="ubicacion"><a href="#ubicacion">Ubicación</a></li>
                    <li data-menuanchor="servicios"><a href="#servicios">Servicios</a></li>
                    <li data-menuanchor="equipo"><a href="#equipo">Equipo</a></li>
                    <li data-menuanchor="contactenos"><a href="#contactenos">Contáctanos</a></li>
                    <li><a href="https://www.alliancegroupca.com/documentos/">Recursos</a></li>
                </ul>

                <div className="social">
                    <a href="https://www.linkedin.com/company/alliancegroupca/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
                    <a href="https://www.facebook.com/Alliance.Group.ca/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a>
                    <a href="mailto:info@alliancegroupca.com" target="_blank" rel="noopener noreferrer"><i className="fas fa-envelope"></i></a>
                    <a href="https://www.alliancegroupca.com/documentos/"><i class="fas fa-file-download"></i></a>
                </div>
            </header>
        );
    }
}

export default Header;
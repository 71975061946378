import React, { Component } from 'react';

import $ from 'jquery';
import ReCAPTCHA from "react-google-recaptcha";

import '../../assets/css/javascript.fullPage.css'
import '../../assets/css/animate.css'
import '../../assets/css/font-awesome-animation.min.css'
import '../../assets/css/home.css'

const TEST_SITE_KEY = "6LflI7sUAAAAAJCwuIdKXNNJBhmiWk10xJ9KdxYL";
const recaptchaRef = React.createRef();

class Home extends Component{
    constructor(props){
        super(props);
        this.state = {
            reCaptcha : null,
            reCapExpired: false
        }
    }
    
    handleChange = value => {
        this.setState({ reCaptcha: value });

        if (value === null)
            this.setState({ reCapExpired: "true" });
        

    };
    
    componentDidMount(){
        $('.accordion').on('click','.ico', function () {
            $(this).parents('.accordion').find('.icos').find('.ico').each(function (index, element) {
                $(this).removeClass('on');
            });

            $(this).addClass('on');

            var index_ico = $(this).index();

            $(this).parents('.accordion').find('.information').find('.info').each(function (index_info, element) {
                $(this).removeClass('on');
                
                if(index_ico === index_info)
                    $(this).addClass('on');
            });
        });

        $('.contactenos').find('form').on('click','button', function (e) {
            // e.preventDefault();
            var filter = 0;
            $(this).parents('form').find('input').each(function (index, element) {
                $(this).removeClass('b-red');
                if($(this).val().length <= 0){
                    filter += 1;
                    $(this).addClass('b-red');
                }
            });

            if(filter === 1 & $(this).parents('form').find('input[name=others]').val().length === 0){
                const recaptchaValue = recaptchaRef.current.getValue();
                if(recaptchaValue.length > 0){
                    $('form').addClass('submit');
                    $.post(process.env.PUBLIC_URL+"/submit_mail.php", {
                        name : $(this).parents('form').find('input[name=name]').val(),
                        email : $(this).parents('form').find('input[name=email]').val(),
                        phone : $(this).parents('form').find('input[name=phone]').val(),
                        message : $(this).parents('form').find('input[name=message]').val()
                        },
                        function (data, textStatus, jqXHR) {
                            console.timeLog(data);   
                            setTimeout(() => {
                                $('form').removeClass('submit');
                                $('form')[0].reset();
                                recaptchaRef.current.reset();
                            }, 3000);     
                        }
                    );
                }
            }
        });
    }
    render(){
        return(
            <div className="home">
                <ul id="menu">
                    <li data-menuanchor="inicio"><a href="#inicio">Inicio</a></li>
                    <li data-menuanchor="nosotros"><a href="#nosotros">Nosotros</a></li>
                    <li data-menuanchor="ubicacion"><a href="#ubicacion">Ubicación</a></li>
                    <li data-menuanchor="servicios"><a href="#servicios">Servicios</a></li>
                    <li data-menuanchor="equipo"><a href="#equipo">Equipo</a></li>
                    <li data-menuanchor="contactenos"><a href="#contactenos">Contáctanos</a></li>
                </ul>
                <div id="fullpage" className="fp-fade-move">
                    <div className="section inicio">
                        <div className="content easeInOutQuart">
                            <div className="box">
                                <div className="center">
                                    <h1>Nuestro principal compromiso<strong>es satisfacer tus necesidades.</strong></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section nosotros">
                        <div className="content easeInOutQuart">
                            <div className="box">
                                <div className="center">
                                    <h2>Acerca <br />de nosotros</h2>
                                    <p>Alliance Group es una compañía con presencia regional conformada por profesionales con experiencia y amplia trayectoria en las siguientes ramas: Contabilidad, Auditoría, Tecnología y Consultoría Financiera. Brindamos la asesoría necesaria para que su empresa logre los objetivos trazados, convirtiéndonos en sus aliados estratégicos.</p>
                                    <p>Nuestros consultores le ayudarán a aumentar la rentabilidad de sus negocios, proporcionándole servicios de apoyo para desarrollar políticas empresariales, estrategias, mejorar sus procesos y sus sistemas de control operativo y financiero.</p>
                                </div>
                                <div className="center">
                                    <h3>Somos su mejor opción en <strong>Outsourcing de Contabilidad, <br />Back Office y Auditoría</strong></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section ubicacion">
                        <div className="content easeInOutQuart">
                            <div className="box">
                                <div className="center">
                                    <h2>Ubicación</h2>
                                    <h3>Región Centroamericana</h3>
                                    <p>Presencia en 5 países.</p>
                                    <p>Más de 200 clientes.</p>
                                    <p>Más de 50 profesionales.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section servicios">
                        <div className="content easeInOutQuart">
                            <div className="box">
                                <div className="center">
                                    <div className="accordion">
                                        <h2>Nuestros Servicios</h2>
                                        <div className="icos">
                                            <div className="ico on"></div>
                                            <div className="ico"></div>
                                            <div className="ico"></div>
                                            <div className="ico"></div>
                                            <div className="ico"></div>
                                        </div>
                                        <div className="information">
                                            <div className="info on outsource">
                                                <div className="photo"></div>
                                                <div className="text">
                                                    <h3>Business process outsource</h3>
                                                    <ul>
                                                        <li>Outsourcing de contabilidad para empresas pequeñas, medianas o grandes.</li>
                                                        <li>Contralores de empresas: servicio integral de procesos de back office, incluyendo elaboración de planillas, tesorería, facturación, contabilidad, etc.</li>
                                                        <li>Asesoría y supervisión de departamento de contabilidad.</li>
                                                        <li>Contabilidad de sociedades y fundaciones offshore panameñas y BVI manejada en las oficinas de Alliance Panamá.</li>
                                                        <li>Servicio de organización contable para nuevas sociedades.</li>
                                                        <li>Consolidación de estados financieros.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="info auditoria">
                                                <div className="photo"></div>
                                                <div className="text">
                                                    <h3>Auditoría</h3>
                                                    <ul>
                                                        <li>Auditoría financiera independiente profesional.</li>
                                                        <li>Auditoría Fiscal.</li>
                                                        <li>Asesoría tributaria y planeamiento fiscal de empresas.</li>
                                                        <li>Due Diligence de empresas objeto de fusiones o adquisiciones.</li>
                                                        <li>Conversión de estados financieros a normas internacionales de información financiera (NIFF Y NIFF PYMES).</li>
                                                        <li>Auditoría interna para salvaguardar de los activos y revisión del sistema de control interno.</li>
                                                        <li>Auditoría forense y experticias contables en litigios.</li>
                                                        <li>Estudios de precios de transferencia.</li>
                                                        <li>Contraloría de empresas intervenidas por bancos u otros acreedores.</li>
                                                        <li>Autorizados para realizar Auditorías a Entidades del Sistema Financiero y Entidades Gubernamentales en El Salvador.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="info financiera">
                                                <div className="photo"></div>
                                                <div className="text">
                                                    <h3>Consultoría Financiera, Operativa y Empresarial</h3>
                                                    <ul>
                                                        <li>Gerentes financieros expertos a tiempo completo o tiempo parcial.</li>
                                                        <li>Diagnóstico corporativos, financieros y organizacionales.</li>
                                                        <li>Creación de normas de Gobierno Corporativo.</li>
                                                        <li>Elaboración de estudios de Factibilidad.</li>
                                                        <li>Análisis, documentación optimización y automatización de procesos operativos y administrativos.</li>
                                                        <li>Estudios de valoración de empresas y acompañamiento en las negociaciones de compra y venta de sociedades.</li>
                                                        <li>Elaboración de manuales de procesos y políticas.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="info aml">
                                                <div className="photo"></div>
                                                <div className="text">
                                                    <h3>Consultoría en AML</h3>
                                                    <ul>
                                                        <li>Elaboración de políticas de prevención de lavado de dinero.</li>
                                                        <li>Asesoría y acompañamiento en el proceso de acreditación ante las autoridades gubernamentales para el cumplimiento de las normativas de prevención de lavado de dinero y financiamiento del terrorismo.</li>
                                                        <li>Capacitación en prevención de lavado de dinero.</li>
                                                        <li>Sistema AML CLIN para monitoreo de transacciones y prevención de lavado de dinero.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="info tecnologia">
                                                <div className="photo"></div>
                                                <div className="text">
                                                    <h3>Optimización de rentabilidad</h3>
                                                    <ul>
                                                        <li>Asesoría y acompañamiento en la reestructuración de financiamientos bancarios: Reducción de costos financieros y mejor estructura de fondeo.</li>
                                                        <li>Rentabilización de las disponibilidades de fondos en bancos y cartera de inversiones.</li>
                                                        <li>Análisis para optimizar la rentabilidad de empresas.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section equipo">
                        <div className="content easeInOutQuart">
                            <div className="box">
                                <div className="center">

                                    <div className="accordion">
                                        <h2>Nuestro Equipo</h2>
                                        <div className="icos">
                                            <div className="ico on"><h4>El Salvador</h4></div>
                                            <div className="ico"><h4>Guatemala</h4></div>
                                            <div className="ico"><h4>Costa Rica</h4></div>
                                            <div className="ico"><h4>Panamá</h4></div>
                                            <div className="ico"><h4>Honduras</h4></div>
                                        </div>
                                        <div className="information">
                                            <div className="info on elsalvador">
                                                <div className="photo"></div>
                                                <div className="text">
                                                    <h3>Alliance Group El Salvador</h3>
                                                    <h4>Manuel Ernesto Rivera</h4>
                                                    <p>Presidente de Alliance Group Central America. Profesional destacado en las ramas de Contabilidad Auditoría y finanzas.</p>
                                                    <p>Su trayectoria le ha permitido tener amplia experiencia para poder asesorar a las empresas en la toma de decisiones clave para el éxito.</p>
                                                </div>
                                            </div>
                                            <div className="info guatemala">
                                                <div className="photo"></div>
                                                <div className="text">
                                                    <h3>Alliance Group Guatemala</h3>
                                                    <h4>Ingrid Betancourt</h4>
                                                    <p>Con amplia experiencia en finanzas corporativas, auditoría y consultoría.</p>
                                                </div>
                                            </div>
                                            <div className="info costarica">
                                                <div className="photo"></div>
                                                <div className="text">
                                                    <h3>Alliance Group Costa Rica</h3>
                                                    <h4>José Manuel Rivera</h4>
                                                    <p>Vicepresidente de Alliance Group Central America y Gerente Regional de Consultoría y tecnología. Cuenta con una excelente formación y experiencia en Consultoría, Procesos y Contabilidad.</p>
                                                </div>
                                            </div>
                                            <div className="info panama">
                                                <div className="photo"></div>
                                                <div className="text">
                                                    <h3>Alliance Group Panamá</h3>
                                                    <h4>Yenisell Caballero</h4>
                                                    <p>Tiene una amplia trayectoría lo cual le ha permitido obtener experiencia en Contabilidad, finanzas y Auditoría.</p>
                                                </div>
                                            </div>
                                            <div className="info honduras">
                                                <div className="photo"></div>
                                                <div className="text">
                                                    <h3>Alliance Group Honduras</h3>
                                                    <h4>Daniel Flores</h4>
                                                    <p>Altamente capacitado en Contabilidad, Auditoria y Consultoría</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section contactenos">
                        <div className="content easeInOutQuart">
                            <div className="box">
                                <div className="center">
                                    <div className="photo"></div>
                                </div>
                                <div className="center">
                                    <h2>Contáctanos</h2>
                                    
                                    <div className="accordion">
                                        <div className="icos">
                                            <div className="ico on"><h4>El Salvador</h4></div>
                                            <div className="ico"><h4>Guatemala</h4></div>
                                            <div className="ico"><h4>Costa Rica</h4></div>
                                            <div className="ico"><h4>Panamá</h4></div>
                                            <div className="ico"><h4>Honduras</h4></div>
                                        </div>
                                        <div className="information">
                                            <div className="info on elsalvador">
                                                <div className="text">
                                                    <p>3a calle poniente, No. 5241, Colonia Lomas Verdes, San Salvador, El Salvador.</p>
                                                    <p>(503) 2263-2960</p>
                                                </div>
                                            </div>
                                            <div className="info guatemala">
                                                <div className="text">
                                                    <p>12 calle 1-25, Zona 10. Edificio Géminis 10 Torre norte No. 602, Guatemala.</p>
                                                    <p>(502) 2338-2489</p>
                                                </div>
                                            </div>
                                            <div className="info costarica">
                                                <div className="text">
                                                    <p>Barrio Naciones Unidas, 200 mts oeste. C.C. del Sur, San José, Costa Rica.</p>
                                                    <p>(506) 4702-7207</p>
                                                </div>
                                            </div>
                                            <div className="info panama">
                                                <div className="text">
                                                    <p>Atrium Tower, piso 19 oficina 05-06, sobre calle 54 este y Ave. Samuel Lewis contiguo a Torre Generali. Obarrio, Bella Vista, Panamá.</p>
                                                    <p>(507) 396-7673/74</p>
                                                </div>
                                            </div>
                                            <div className="info honduras">
                                                <div className="text">
                                                    <div className="block"><p>Centro Comercial Santa Mónica Este, Sector Cervecería, 3ª. Ave., 15-18 Calle, Local 204 San Pedro Sula, Cortés Honduras, C.A.</p></div>
                                                    <div className="block"><p>Colonia Lara 2 Ave., 3-4 Calle, No. 801-A. Tegucigalpa, M.D.C., Honduras, C.A.</p></div>
                                                    <p>(504) 2565-3153</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div className="form">
                                        <form action="3669ca6c6d7a491f29daec4904d15a22" method="post" onSubmit={e => {e.preventDefault();}}>
                                            <input type="text" name="others" placeholder="others" />
                                            <input type="text" name="name" placeholder="Nombre Completo" required autoComplete="off" />
                                            <input type="text" name="email" placeholder="@mail.com" required autoComplete="off" />
                                            <input type="text" name="phone" placeholder="Teléfono" required autoComplete="off" />
                                            <input type="text" name="message" placeholder="Mensaje" required autoComplete="off" />
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                sitekey={TEST_SITE_KEY}
                                                onChange={this.handleChange}
                                                size = "normal"
                                            />
                                            <button><span>Enviar</span> <span>Enviando <i className="fa fa-spinner faa-spin animated"></i></span></button>
                                        </form>
                                    </div>
                                </div>
                       
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Home;